import { Card, CardLevel, Drawer, PaddingLevel } from '@pinpointhq/thumbtack';
import * as React from 'react';
import ReactTable from 'react-table';
import { Application } from '../../../../../../../javascript/models';
import { DrawerHeader } from '../../../../../../shared/DrawerHeader';
import humanize from '../../../../../../shared/utilities/humanize';
import { useSpraypaintTableNamedParams } from '../../../../../shared/hooks/useSpraypaintTable';
import NewPaginationComponent from '../../../../../shared/NewPaginationComponent';

export function ApplicationDrawer({ handleClose, job }) {
  const { tableProps, fetchData } = useSpraypaintTableNamedParams({
    initialScope: Application.where({ job_id: job.id }).includes(['jobSeeker']),
    searchIdentifier: 'applications',
  });

  const paginationProps = React.useCallback(() => tableProps, [tableProps]);

  const columns = [
    {
      Header: 'Name',
      accessor: 'jobSeeker.fullName',
    },
    {
      Header: 'Email',
      accessor: 'jobSeeker.email',
    },
    {
      Cell: (row: { original: Application }) => {
        return humanize(row.original.status);
      },
      Header: 'Status',
      accessor: 'status',
    },
  ];

  return (
    <Drawer isOpen={true} onClose={handleClose} maxWidth={1000}>
      <DrawerHeader title="Application" handleClose={handleClose} />
      <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.REGULAR}>
        <ReactTable
          {...tableProps}
          columns={columns}
          PaginationComponent={NewPaginationComponent}
          getPaginationProps={paginationProps}
          className="-unthemed"
          minRows={1}
        />
      </Card>
    </Drawer>
  );
}
