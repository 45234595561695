import { AnchorButton } from '@pinpointhq/thumbtack';
import { format } from 'date-fns';
import * as React from 'react';
import ReactTable from 'react-table';
import { adminReportPath } from '../../../../javascript/application/ts_routes';
import { Report } from '../../../../javascript/models';
import humanize from '../../../shared/utilities/humanize';
import NewPaginationComponent from '../../shared/NewPaginationComponent';

export function ReportTable({ tableProps }) {
  const paginationProps = React.useCallback(() => tableProps, [tableProps]);

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Cell: (row: { original: Report }) => {
        return humanize(row.original.status);
      },
      Header: 'Status',
      accessor: 'status',
    },
    {
      Cell: (row: { original: Report }) => {
        return <div>{format(row.original.createdAt, 'MMMM Do YYYY HH:mm')}</div>;
      },
      Header: 'Created At',
      accessor: 'created_at',
    },
    {
      Cell: (row: { original: Report }) => {
        const { original: report } = row;
        return (
          <AnchorButton
            href={adminReportPath(report.id)}
            target="_blank"
            text="Download"
            minimal={true}
            loading={report.status !== 'completed'}
          />
        );
      },
      Header: '',
      accessor: 'id',
      sortable: false,
      width: 170,
    },
  ];

  return (
    <ReactTable
      {...tableProps}
      columns={columns}
      PaginationComponent={NewPaginationComponent}
      getPaginationProps={paginationProps}
      className="-unthemed"
      minRows={1}
    />
  );
}
