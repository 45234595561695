import {
  Button,
  Card,
  CardLevel,
  Checkbox,
  Col,
  DateInput,
  Divider,
  Drawer,
  Empty,
  FormGroup,
  Frow,
  IconButton40,
  IconNames16,
  Input,
  Intent,
  PaddingLevel,
  Select,
  Subheading2,
  TextArea,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import { Company } from '../../../javascript/models';
import { createToast } from '../../FlashToaster';
import { DrawerHeader } from '../../shared/DrawerHeader';

export function NewOrganisationForm({ fetchData, handleClose, regionOptions, workstreamOptions }) {
  const [name, setName] = React.useState<string>('');
  const [registeredCompanyNumber, setRegisteredCompanyNumber] = React.useState<string>('');
  const [uniqueIdentifierDivision, setUniqueIdentifierDivision] = React.useState<string>('');
  const [companyAddress, setCompanyAddress] = React.useState<string>('');
  const [workstream, setWorkstream] = React.useState<string>('');
  const [considerApplicant, setConsiderApplicant] = React.useState<boolean>(true);
  const [provideVisaSponsor, setProvideVisaSponsor] = React.useState<boolean>(true);
  const [primaryName, setPrimaryName] = React.useState<string>('');
  const [primaryPhone, setPrimaryPhone] = React.useState<string>('');
  const [primaryEmail, setPrimaryEmail] = React.useState<string>('');
  const [secondaryName, setSecondaryName] = React.useState<string>('');
  const [secondaryPhone, setSecondaryPhone] = React.useState<string>('');
  const [secondaryEmail, setSecondaryEmail] = React.useState<string>('');
  const [premierMember, setPremierMember] = React.useState<boolean>(false);

  const [applicationsReviewed, setApplicationsReviewed] = React.useState<Date>();
  const [interviewsCompleted, setInterviewsCompleted] = React.useState<Date>();
  const [offersMade, setOffersMade] = React.useState<Date>();
  const [hiresConfirmed, setHiresConfirmed] = React.useState<Date>();

  const handleNameChange = (event) => setName(event.currentTarget.value);
  const handleregisteredCompanyNumberChange = (event) => setRegisteredCompanyNumber(event.currentTarget.value);
  const handleUniqueIdentifierDivisionChange = (event) => setUniqueIdentifierDivision(event.currentTarget.value);
  const handleCompanyAddressChange = (event) => setCompanyAddress(event.currentTarget.value);
  const handleWorkstreamChange = (selectedItem) => setWorkstream(selectedItem.value);
  const handleConsiderChange = (event) => setConsiderApplicant(event.currentTarget.checked);
  const handleProvideVisaSponsorChange = (event) => setProvideVisaSponsor(event.currentTarget.checked);

  const handlePrimaryNameChange = (event) => setPrimaryName(event.currentTarget.value);
  const handlePrimaryPhoneChange = (event) => setPrimaryPhone(event.currentTarget.value);
  const handlePrimaryEmailChange = (event) => setPrimaryEmail(event.currentTarget.value);
  const handleSecondaryNameChange = (event) => setSecondaryName(event.currentTarget.value);
  const handleSecondaryPhoneChange = (event) => setSecondaryPhone(event.currentTarget.value);
  const handleSecondaryEmailChange = (event) => setSecondaryEmail(event.currentTarget.value);

  const handlePremierMemberChange = (event) => setPremierMember(event.currentTarget.checked);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const handleCreate = () => {
    if (
      !name ||
      !registeredCompanyNumber ||
      !companyAddress ||
      !workstream ||
      !primaryName ||
      !primaryPhone ||
      !primaryEmail ||
      !applicationsReviewed ||
      !interviewsCompleted ||
      !offersMade ||
      !hiresConfirmed
    ) {
      createToast({ type: 'error', text: 'Please fill in all required fields' });
      return;
    }

    if (internships.length < 1) {
      createToast({ type: 'error', text: 'Please add at least one internship' });
      return;
    }

    if (
      internships.some(
        (internship) =>
          !internship.title ||
          !internship.description ||
          !internship.pay ||
          !internship.workstream ||
          !internship.location ||
          !internship.number,
      )
    ) {
      createToast({ type: 'error', text: 'Please fill in all required fields for internships' });
      return;
    }
    // We should validate stuff here
    setIsSubmitting(true);
    new Company({
      name,
      registeredCompanyNumber,
      uniqueIdentifierDivision,
      companyAddress,
      workstream,
      considerApplicant,
      provideVisaSponsor,
      primaryName,
      primaryPhone,
      primaryEmail,
      secondaryName,
      secondaryPhone,
      secondaryEmail,
      premierMember,
      internships,
      applicationsReviewed,
      interviewsCompleted,
      offersMade,
      hiresConfirmed,
    })
      .save()
      .then((success) => {
        setIsSubmitting(false);
        if (success) {
          createToast({ type: 'success', text: 'Organisation created successfully' });
          fetchData();
          handleClose();
        }
      });
  };

  // Internships
  const [internships, setInternships] = React.useState<
    {
      title: string;
      pay: string;
      number: number;
      workstream: string;
      remote: boolean;
      location: string;
      postcode: string;
      description: string;
      longitude: string;
      latitude: string;
    }[]
  >([
    {
      title: '',
      pay: '',
      number: 1,
      workstream: '',
      remote: false,
      location: '',
      postcode: '',
      description: '',
      longitude: '',
      latitude: '',
    },
  ]);

  const selectedCompanyWorkstream = workstreamOptions.find((option) => option.value === workstream);

  return (
    <Drawer isOpen={true} onClose={handleClose} maxWidth={800}>
      <DrawerHeader title="New organisation" handleClose={handleClose} />
      <Divider muted={true} />
      <div style={{ overflowY: 'scroll' }}>
        <Card
          paddingLevel={PaddingLevel.REGULAR}
          level={CardLevel.NONE}
          cardInnerProps={{ className: 'pad-h-5 pad-v-3' }}
        >
          <Subheading2 className="mar-b-2">Basic Info</Subheading2>
          <FormGroup label="Name*">
            <Input type="text" value={name} onChange={handleNameChange} />
          </FormGroup>
          <FormGroup label="Premier memeber*">
            <Checkbox checked={premierMember} onChange={handlePremierMemberChange} label="Is a premier member" />
          </FormGroup>
          <FormGroup label="Registered company number*">
            <Input type="text" value={registeredCompanyNumber} onChange={handleregisteredCompanyNumberChange} />
          </FormGroup>
          <FormGroup label="Unique identifier division">
            <Input type="text" value={uniqueIdentifierDivision} onChange={handleUniqueIdentifierDivisionChange} />
          </FormGroup>
          <FormGroup label="Company address*">
            <Input type="text" value={companyAddress} onChange={handleCompanyAddressChange} />
          </FormGroup>
          <FormGroup label="Workstream*">
            <Select
              fill={true}
              items={workstreamOptions}
              activeItem={selectedCompanyWorkstream}
              onItemSelect={handleWorkstreamChange}
              buttonProps={{ large: true }}
            />
          </FormGroup>
          <Divider className="mar-v-3" muted={true} />
          <Subheading2 className="mar-b-2">Sponsorship</Subheading2>
          <Frow>
            <Col md={{ width: '1-2' }}>
              <Checkbox
                checked={considerApplicant}
                onChange={handleConsiderChange}
                label="Consider visa applications"
              />
            </Col>
            <Col md={{ width: '1-2' }}>
              <Checkbox
                checked={provideVisaSponsor}
                onChange={handleProvideVisaSponsorChange}
                label="Willing to provide visa sponsorship"
              />
            </Col>
          </Frow>
          <Divider className="mar-v-3" muted={true} />
          <Subheading2 className="mar-b-2">Contacts</Subheading2>
          <FormGroup label="Primary name*">
            <Input type="text" value={primaryName} onChange={handlePrimaryNameChange} />
          </FormGroup>
          <FormGroup label="Primary phone*">
            <Input type="text" value={primaryPhone} onChange={handlePrimaryPhoneChange} />
          </FormGroup>
          <FormGroup label="Primary email*">
            <Input type="email" value={primaryEmail} onChange={handlePrimaryEmailChange} />
          </FormGroup>
          <FormGroup label="Secondary name">
            <Input type="text" value={secondaryName} onChange={handleSecondaryNameChange} />
          </FormGroup>
          <FormGroup label="Secondary phone">
            <Input type="text" value={secondaryPhone} onChange={handleSecondaryPhoneChange} />
          </FormGroup>
          <FormGroup label="Secondary email">
            <Input type="email" value={secondaryEmail} onChange={handleSecondaryEmailChange} />
          </FormGroup>
          <Divider className="mar-v-3" muted={true} />
          <Internships
            internships={internships}
            setInternships={setInternships}
            workstreamOptions={workstreamOptions}
            regionOptions={regionOptions}
          />
          <Divider className="mar-v-3" muted={true} />
          <Subheading2 className="mar-b-2">Timelines</Subheading2>
          <Frow gutters={16} verticalGutters={8}>
            <Col md={{ width: '1-2' }}>
              <FormGroup label="Applications reviewed by*">
                <DateInput
                  fill={true}
                  value={applicationsReviewed}
                  onChange={(event) => setApplicationsReviewed(event)}
                />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-2' }}>
              <FormGroup label="Interviews completed by*">
                <DateInput
                  fill={true}
                  value={interviewsCompleted}
                  onChange={(event) => setInterviewsCompleted(event)}
                />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-2' }}>
              <FormGroup label="Offers made by*">
                <DateInput fill={true} value={offersMade} onChange={(event) => setOffersMade(event)} />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-2' }}>
              <FormGroup label="Hires confirmed by*">
                <DateInput fill={true} value={hiresConfirmed} onChange={(event) => setHiresConfirmed(event)} />
              </FormGroup>
            </Col>
          </Frow>
          <div className="mar-t-2">
            <Button text="Create Organisation" large={false} onClick={handleCreate} loading={isSubmitting} />
          </div>
        </Card>
      </div>
    </Drawer>
  );
}

function Internships({ internships, setInternships, regionOptions, workstreamOptions }) {
  const handleAddInternships = () => {
    setInternships([
      ...internships,
      {
        title: '',
        pay: '',
        number: 1,
        workstream: '',
        remote: false,
        location: '',
        postcode: '',
        description: '',
        longitude: '',
        latitude: '',
      },
    ]);
  };

  const handleInternshipDelete = (index) => {
    const updatedInternships = [...internships];
    updatedInternships.splice(index, 1);
    setInternships(updatedInternships);
  };

  const handleInternshipChange = (index, internshipObject) => {
    const updatedInternships = [...internships];
    updatedInternships.splice(index, 1, internshipObject);
    setInternships(updatedInternships);
  };

  return (
    <>
      <Subheading2 className="mar-b-2">Internships</Subheading2>
      <Frow>
        {internships.map((internship, index) => {
          return (
            <Col key={`internship-${index}`}>
              <InternshipForm
                index={index}
                internship={internship}
                handleInternshipChange={handleInternshipChange}
                handleInternshipDelete={handleInternshipDelete}
                regionOptions={regionOptions}
                workstreamOptions={workstreamOptions}
              />
            </Col>
          );
        })}
      </Frow>
      {internships.length < 1 && <Empty text="No Internships added" compact={true} />}
      <Frow justifyContent="center" className="mar-t-1">
        <div>
          <Button text="Add another Internship" large={false} minimal={true} onClick={handleAddInternships} />
        </div>
      </Frow>
    </>
  );
}

function InternshipForm({
  handleInternshipChange,
  handleInternshipDelete,
  index,
  internship,
  regionOptions,
  workstreamOptions,
}) {
  const selectedInternshipWorkstream = workstreamOptions.find((option) => option.value === internship.workstream);
  const selectedRegionOption = regionOptions.find((option) => option.value === internship.location);

  return (
    <Card paddingLevel={PaddingLevel.CONDENSED} level={CardLevel.FILLED} className="mar-b-2">
      <Frow gutters={16} flexWrap="nowrap">
        <div className="col-flex-grow-1">
          <FormGroup label="Title*">
            <Input
              type="text"
              value={internship.title}
              onChange={(event) => handleInternshipChange(index, { ...internship, title: event.currentTarget.value })}
            />
          </FormGroup>
        </div>
        <div>
          <IconButton40
            intent={Intent.DANGER}
            icon={IconNames16.DELETE}
            onClick={() => handleInternshipDelete(index)}
          />
        </div>
      </Frow>
      <FormGroup label="Description*">
        <TextArea
          value={internship.description}
          onChange={(event) => handleInternshipChange(index, { ...internship, description: event.currentTarget.value })}
        />
      </FormGroup>
      <Frow gutters={16} verticalGutters={8}>
        <Col md={{ width: '1-2' }}>
          <FormGroup label="Pay*">
            <Input
              type="text"
              value={internship.pay}
              onChange={(event) => handleInternshipChange(index, { ...internship, pay: event.currentTarget.value })}
            />
          </FormGroup>
        </Col>
        <Col md={{ width: '1-2' }}>
          <FormGroup label="Headcount*">
            <Input
              type="number"
              value={internship.number}
              onChange={(event) => handleInternshipChange(index, { ...internship, number: event.currentTarget.value })}
            />
          </FormGroup>
        </Col>
      </Frow>
      <Frow gutters={16} verticalGutters={8}>
        <Col md={{ width: '1-2' }}>
          <FormGroup label="Workstream*">
            <Select
              fill={true}
              items={workstreamOptions}
              activeItem={selectedInternshipWorkstream}
              onItemSelect={(selectedItem) =>
                handleInternshipChange(index, { ...internship, workstream: selectedItem.value })
              }
              buttonProps={{ large: true }}
            />
          </FormGroup>
        </Col>
        <Col md={{ width: '1-2' }}>
          <FormGroup label="Remote*">
            <Checkbox
              checked={internship.remote}
              onChange={(event) =>
                handleInternshipChange(index, { ...internship, remote: event.currentTarget.checked })
              }
              label="Will offer remote internships"
            />
          </FormGroup>
        </Col>
      </Frow>
      <Frow gutters={16} verticalGutters={8}>
        <Col md={{ width: '1-2' }}>
          <FormGroup label="Region">
            <Select
              items={regionOptions}
              onItemSelect={(selectedItem) =>
                handleInternshipChange(index, { ...internship, location: selectedItem.value })
              }
              fill={true}
              buttonProps={{ large: true }}
              activeItem={selectedRegionOption}
            />
          </FormGroup>
        </Col>
      </Frow>
    </Card>
  );
}
