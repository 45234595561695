import {
  Body,
  Button,
  Card,
  CardLevel,
  Col,
  Divider,
  FormGroup,
  Frow,
  IconNames16,
  ISelectOption,
  MultiSelect,
  PaddingLevel,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import { useState } from 'react';
import { visit } from 'turbolinks';
import useVisibility from '../../../shared/hooks/useVisibility';
import Search from '../../../shared/Search';

interface IFilters {
  companies?: string;
  grad_year?: string;
  initialFilter?: any;
  preference_1?: string;
  preference_2?: string;
  preference_3?: string;
  preference_4?: string;
  tier?: string;
  sponsorship?: string;
  education?: string;
  source?: string;
  universities?: string;
  degree?: string;
}

export default function Filters({
  handleApplyFilters,
  meta,
  searchTerm,
  selection,
  setSearchTerm,
  setSelection,
  initialFilter = {},
  exportPath = null,
}) {
  const { isOpen, toggleOpen } = useVisibility(false);
  const [filters, setFilters] = useState<IFilters>(initialFilter);
  const [preferences, setPreferences] = React.useState<ISelectOption[]>([]);
  const [sources, setSources] = React.useState<ISelectOption[]>([]);
  const [companyOptions, setCompanyOptions] = React.useState<ISelectOption[]>([]);
  const [universityOptions, setUniversityOptions] = React.useState<ISelectOption[]>([]);
  const [degreeSubjectOptions, setDegreeSubjectOptions] = React.useState<ISelectOption[]>([]);

  React.useEffect(() => {
    if (meta && !preferences.length && !sources.length) {
      setPreferences(meta.preferences);
      setSources(meta.sources);
      setCompanyOptions(meta.companies);
      setUniversityOptions(meta.universities);
      setDegreeSubjectOptions(meta.degree_subjects);
    }
  }, [meta]);

  React.useEffect(() => {
    document.addEventListener(`${identifier}:search`, handleSearch);
    return () => {
      document.removeEventListener(`${identifier}:search`, handleSearch);
    };
  }, [searchTerm]);

  const handleSearch = (event) => {
    setSearchTerm(event.detail.searchTerm);
  };

  const handleFilter = () => {
    handleApplyFilters(filters);
  };

  const handleExport = () => {
    visit(exportPath({ filters: filters }));
  };

  const universityTierOptions = ['1', '2', '3'].map((tier) => {
    return { value: tier, label: tier };
  });

  const sponsorshipOptions = ['No', 'Yes'].map((booleanOption) => {
    return { value: booleanOption, label: booleanOption };
  });

  const graduationYearOptions = [
    '2018',
    '2019',
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
    '2026',
    '2027',
    '2028',
    '2029',
    '2030',
  ].map((yearOption) => {
    return { value: yearOption, label: yearOption };
  });

  const educationOptions = [
    { value: 'undergraduate', label: 'Undergraduate' },
    { value: 'postgraduate', label: 'Postgraduate' },
  ];

  const handleFilterOneChange = (items) => setFilters({ ...filters, preference_1: items.map((item) => item.value) });
  const handleFilterTwoChange = (items) => setFilters({ ...filters, preference_2: items.map((item) => item.value) });
  const handleFilterThreeChange = (items) => setFilters({ ...filters, preference_3: items.map((item) => item.value) });
  const handleFilterFourChange = (items) => setFilters({ ...filters, preference_4: items.map((item) => item.value) });
  const handleTierChange = (items) => setFilters({ ...filters, tier: items.map((item) => item.value) });
  const handleSponsorshipChange = (items) => setFilters({ ...filters, sponsorship: items.map((item) => item.value) });
  const handleEducationChange = (items) => setFilters({ ...filters, education: items.map((item) => item.value) });
  const handleSourceChange = (items) => setFilters({ ...filters, source: items.map((item) => item.value) });
  const handleCompanyChange = (items) => setFilters({ ...filters, companies: items.map((item) => item.value) });
  const handleGradYearOptionsChange = (items) => setFilters({ ...filters, grad_year: items.map((item) => item.value) });
  const handleUniversityChange = (items) => setFilters({ ...filters, universities: items.map((item) => item.value) });
  const handleDegreeSubjectChange = (items) => setFilters({ ...filters, degree: items.map((item) => item.value) });

  const handleCancel = () => setSelection([]);

  const identifier = 'candidate-indices';
  return (
    <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.EXPANDED}>
      <Frow gutters={16} verticalGutters={16}>
        <div className="bp3-col--flex-grow-1">
          {selection.length > 0 ? <ActionBar {...{ selection, handleCancel }} /> : <Search target={identifier} />}
        </div>
        <div>
          <Button minimal={true} text="Filters" large={true} onClick={toggleOpen} />
        </div>
        {/*<div>*/}
        {/*  <Button minimal={true} text="Export" large={true} onClick={handleExport} />*/}
        {/*</div>*/}
      </Frow>
      {isOpen && (
        <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR} className="mar-t-2">
          <Frow gutters={16} verticalGutters={16} alignContent="flex-end" alignItems="flex-end">
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Preference one" style={{ marginBottom: 0 }}>
                <MultiSelect
                  items={preferences}
                  loading={!meta}
                  fill={true}
                  condensed={true}
                  handleChange={handleFilterOneChange}
                />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Preference two" style={{ marginBottom: 0 }}>
                <MultiSelect
                  items={preferences}
                  loading={!meta}
                  fill={true}
                  condensed={true}
                  handleChange={handleFilterTwoChange}
                />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Preference three" style={{ marginBottom: 0 }}>
                <MultiSelect
                  items={preferences}
                  loading={!meta}
                  fill={true}
                  condensed={true}
                  handleChange={handleFilterThreeChange}
                />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Preference four" style={{ marginBottom: 0 }}>
                <MultiSelect
                  items={preferences}
                  loading={!meta}
                  fill={true}
                  condensed={true}
                  handleChange={handleFilterFourChange}
                />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <FormGroup label="University tier" style={{ marginBottom: 0 }}>
                <MultiSelect
                  items={universityTierOptions}
                  loading={!meta}
                  fill={true}
                  condensed={true}
                  handleChange={handleTierChange}
                />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Sponsorship Required" style={{ marginBottom: 0 }}>
                <MultiSelect
                  items={sponsorshipOptions}
                  loading={!meta}
                  fill={true}
                  condensed={true}
                  handleChange={handleSponsorshipChange}
                />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Level of education" style={{ marginBottom: 0 }}>
                <MultiSelect
                  items={educationOptions}
                  loading={!meta}
                  fill={true}
                  condensed={true}
                  handleChange={handleEducationChange}
                />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Source" style={{ marginBottom: 0 }}>
                <MultiSelect
                  items={sources}
                  loading={!meta}
                  fill={true}
                  condensed={true}
                  handleChange={handleSourceChange}
                />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Company" style={{ marginBottom: 0 }}>
                <MultiSelect
                  items={companyOptions}
                  loading={!meta}
                  fill={true}
                  condensed={true}
                  handleChange={handleCompanyChange}
                />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Year of graduation" style={{ marginBottom: 0 }}>
                <MultiSelect
                  items={graduationYearOptions}
                  loading={!meta}
                  fill={true}
                  condensed={true}
                  handleChange={handleGradYearOptionsChange}
                />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <FormGroup label="University" style={{ marginBottom: 0 }}>
                <MultiSelect
                  items={universityOptions}
                  loading={!meta}
                  fill={true}
                  condensed={true}
                  handleChange={handleUniversityChange}
                />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Degree Subject" style={{ marginBottom: 0 }}>
                <MultiSelect
                  items={degreeSubjectOptions}
                  loading={!meta}
                  fill={true}
                  condensed={true}
                  handleChange={handleDegreeSubjectChange}
                />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <Button large={true} fill={true} text="Apply Filters" onClick={handleFilter} />
            </Col>
          </Frow>
        </Card>
      )}
    </Card>
  );
}

function ActionBar({ handleCancel, selection }) {
  const handleClick = () => {
    // Alerter.create({
    //   cancelButtonText: 'Cancel',
    //   confirmButtonText: 'Yes',
    //   onConfirm: () => {
    //     $.ajax({
    //       data: { job_seeker_ids: selection.map((candidate) => candidate.id), authenticity_token: csrfToken() },
    //       dataType: 'json',
    //       success: () => {
    //         createToast({
    //           text: `Successfully queued assignment for ${selection.length} candidates`,
    //           type: 'success',
    //         });
    //         handleCancel()
    //       },
    //       type: 'POST',
    //       url: selectionAssignAdminProcessingJobSeekersPath(),
    //     });
    //   },
    //   text: `Are you sure you want to assign ${selection.length} candidate`,
    // });
  };

  const defaultStyles = { padding: '4px 4px 4px 28px' };
  return (
    <Card elevated={true} level={CardLevel.FILLED} padded={false} style={{ ...defaultStyles }}>
      <div className="frow frow--items-center frow--justify-between">
        <div style={{ flex: '1 0 0%' }}>
          <div className="frow frow--items-center">
            <Body className="mar-r-2">{selection.length} candidates selected</Body>
            <Button minimal={true} onClick={handleClick} text="Assign" />
          </div>
        </div>
        <div>
          <div className="frow frow--items-center">
            <ItemDivider />
            <Button icon={IconNames16.CLOSE} onClick={handleCancel} text="" minimal={true} />
          </div>
        </div>
      </div>
    </Card>
  );
}

function ItemDivider() {
  return <Divider muted={true} className="mar-h-1" style={{ height: '24px' }} />;
}
