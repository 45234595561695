import {
  Alerter,
  Button,
  Classes,
  Frow,
  Heading2,
  IconButton40,
  IconNames16,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import ReactTable from 'react-table';
import { Company, Job } from '../../../../javascript/models';
import { createToast } from '../../../FlashToaster';
import humanize from '../../../shared/utilities/humanize';
import PaginationComponent from '../../candidates/awaiting/PaginationComponent';
import { ApplicationDrawer } from '../../programmes/companies/show/programmeDetails/jobs/ApplicationDrawer';
import { ChangeWorkstreamDialog } from '../../programmes/companies/show/programmeDetails/jobs/ChangeWorkstreamDialog';
import { CloseJobDialog } from '../../programmes/companies/show/programmeDetails/jobs/CloseJobDialog';
import { NewJobDialog } from '../../programmes/companies/show/programmeDetails/jobs/NewJobDialog';
import { AllocateDialog } from '../../shared/AllocateDialog';

export function CompanyJobs({
  company,
  fetchData,
  workstreamOptions,
}: {
  company: Company;
  fetchData(): void;
  workstreamOptions: any;
}) {
  const [selectedJobId, setSelectedJobId] = React.useState();
  const [selectedWorkstreamChangeJob, setSelectedWorkstreamChangeJob] = React.useState<Job>();
  const [selectedClosingJob, setSelectedClosingJob] = React.useState<Job>();
  const [newJob, setNewJob] = React.useState<Job>();
  const [selectedJob, setSelectedJob] = React.useState<Job>();

  const handleWorkstreamClose = () => setSelectedWorkstreamChangeJob(null);
  const handleCloseJobClose = () => setSelectedClosingJob(null);
  const handleCreateJob = () => setNewJob(new Job({ companyId: company.id }));
  const handleNewJobClose = () => setNewJob(null);

  const handleJobClose = () => setSelectedJob(null);

  const columns = [
    {
      Cell: (row: { original: Job }) => {
        const handleJobSelect = () => setSelectedJob(row.original);
        return (
          <div>
            <span className={Classes.LINK} onClick={handleJobSelect}>
              {row.original.title}
            </span>
          </div>
        );
      },
      Header: 'Internship Title',
      accessor: 'title',
      sortable: false,
    },
    {
      Header: 'Cycle',
      accessor: 'cycle.name',
      sortable: false,
    },
    {
      Header: 'Programme',
      accessor: 'programme.name',
      sortable: false,
    },
    {
      Header: 'Workstream',
      accessor: 'workstream',
      sortable: false,
    },
    {
      Header: 'Region',
      accessor: 'region',
      sortable: false,
    },
    {
      Cell: (row) => {
        return <div>{humanize(row.original.progress)}</div>;
      },
      Header: 'Progress',
      accessor: 'progress',
      sortable: false,
    },
    {
      Header: 'Headcount',
      accessor: 'headcount',
      sortable: false,
      width: 120,
    },
    {
      Cell: (row: any) => {
        const { original: job } = row;
        const handleClick = () => setSelectedJobId(job.id);

        const handleChangeWorkstream = () => {
          setSelectedWorkstreamChangeJob(job);
        };

        const handleCloseJob = () => {
          setSelectedClosingJob(job);
        };

        const handleDelete = () => {
          const key = 'job-delete';
          Alerter.create({
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, delete',
            intent: Intent.NONE,
            onConfirm: () => {
              createToast({ type: 'success', key, isLoading: true, text: 'Deleting job' });
              job.destroy().then((success) => {
                if (success) {
                  createToast({ type: 'success', key, text: 'Successfully deleted job' });
                  fetchData();
                } else {
                  createToast({ type: 'error', key, text: 'Unable to delete job' });
                }
              });
            },
            text:
              'Are you sure you want to delete this internship? Any candidates allocated will be prioritised for future allocations. This action cannot be undone. You should only use this option if there is a genuine mistake, otherwise please close the job',
          });
        };

        const handleCreateInPinpoint = () => {
          job.createInPinpoint = true;
          job.save().then((success) => {
            if (success) {
              createToast({ type: 'success', text: 'Successfully created job in Pinpoint' });
              fetchData();
            } else {
              createToast({ type: 'error', text: 'Unable to create job in Pinpoint' });
            }
          });
        };

        return (
          <div>
            <Popover>
              <IconButton40 icon={IconNames16.OVERFLOW} />
              <Menu>
                <MenuItem text="Allocate" onClick={handleClick} />
                <MenuItem text="Change Workstream" onClick={handleChangeWorkstream} />
                <MenuItem text="Close" onClick={handleCloseJob} />
                {!row.original.pinpointJobId && row.original.cycleId === '4' && (
                  <MenuItem text="Create in Pinpoint" onClick={handleCreateInPinpoint} />
                )}
                <MenuDivider />
                <MenuItem text="Delete" onClick={handleDelete} intent={Intent.DANGER} />
              </Menu>
            </Popover>
          </div>
        );
      },
      Header: '',
      sortable: false,
      width: 80,
    },
  ];

  const handleClose = () => setSelectedJobId(null);

  const handleSuccess = () => window.location.reload();

  return (
    <div>
      <Frow justifyContent="space-between" alignContent="center" gutters={8} verticalGutters={8}>
        <div>
          <Heading2>Internships</Heading2>
        </div>
        <div>
          <Button icon={IconNames16.ADD} text="New job" onClick={handleCreateJob} />
        </div>
      </Frow>

      <ReactTable
        columns={columns}
        data={company.jobs}
        PaginationComponent={PaginationComponent}
        loading={false}
        className="-unthemed"
        minRows={1}
        pageSize={5}
      />
      {selectedJobId && (
        <AllocateDialog
          isOpen={!!selectedJobId}
          jobId={selectedJobId}
          handleClose={handleClose}
          handleSuccess={handleSuccess}
        />
      )}
      <ChangeWorkstreamDialog
        fetchData={fetchData}
        isOpen={!!selectedWorkstreamChangeJob}
        job={selectedWorkstreamChangeJob}
        handleClose={handleWorkstreamClose}
        workstreamOptions={workstreamOptions}
      />
      <CloseJobDialog
        fetchData={fetchData}
        isOpen={!!selectedClosingJob}
        job={selectedClosingJob}
        handleClose={handleCloseJobClose}
      />
      <NewJobDialog
        isOpen={!!newJob}
        job={newJob}
        handleClose={handleNewJobClose}
        workstreamOptions={workstreamOptions}
        fetchData={fetchData}
      />
      {selectedJob && <ApplicationDrawer job={selectedJob} handleClose={handleJobClose} />}
    </div>
  );
}
